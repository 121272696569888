<template>
  <div>
    <p>お探しのページは見つかりません</p>
  </div>
</template>

<script>
export default {
  name: "notFound"
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
</style>
